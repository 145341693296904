import React from 'react';
import styled from '@emotion/styled';
import { H4, P3 } from 'components/Atom/Typo';
import LinkButton from 'components/Atom/Link';

const Component = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  ${H4} {
    font-size: 14px;
    color: ${(props) => props.theme.colors.grey500};
  }
  margin-bottom: 16px;
`;

const LinkMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Link = styled(LinkButton)`
  margin-bottom: 4px;
  text-decoration: none;
  justify-content: start;
  &:last-child {
    margin-bottom: 0px;
  }

  ${P3} {
    color: ${(props) => props.theme.colors.grey500};
  }
`;

interface FooterListProps {
  title: string;
  to?: string;
  list?: {
    title: string;
    to: string;
  }[];
  className?: string;
}

const FooterList = ({ title, list, to, className }: FooterListProps) => {
  return (
    <Component className={className}>
      <Header>
        {list ? (
          <H4>{title}</H4>
        ) : (
          <Link to={to || ''}>
            <H4>{title}</H4>
          </Link>
        )}
      </Header>
      <LinkMenu>
        {list &&
          list.map((element) => {
            return (
              <Link key={element.to} to={element.to}>
                <P3>{element.title}</P3>
              </Link>
            );
          })}
      </LinkMenu>
    </Component>
  );
};

export default FooterList;
