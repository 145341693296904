import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { LogoText } from 'components/Atom/Icon';
import { ContainerGrid, ItemGrid } from 'components/Atom/DefualtLayout';

import { H4 } from 'components/Atom/Typo';

import LinkButton from 'components/Atom/Link';
import NavLinkList from 'components/Molecule/LinkList/NavLinkList';

import { FluidObject } from 'gatsby-image';
import Image from 'components/Molecule/Image';
import Anchor from 'components/Atom/Anchor';

const Component = styled.div`
  width: 100%;
  position: fixed;

  top: 0px;
  left: 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.11);
`;

const MainMenu = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  padding-left: 60px;
  padding-right: 60px;

  background-color: white;
`;

const LogoWrapper = styled(LinkButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* svg {
    height: 19px;
    width: 82.5px;
  } */
`;

const NavButtonWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavButton = styled(LinkButton)<{ isMouseOver: boolean }>`
  background-color: transparent;
  width: 180px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${H4} {
    font-size: 18px;
    color: ${(props) => props.theme.colors.grey700};
  }

  ${(props) => {
    if (props.isMouseOver) {
      return css`
        ${H4} {
          color: ${props.theme.colors.primary};
        }
      `;
    }
    return css``;
  }}
`;

const ExpandMenu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  bottom: 0px;
  transform: translate(0%, 100%);
  width: 100%;

  height: 150px;

  background-color: white;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.11);
  ${(props) => {
    if (props.isOpen) {
      return css`
        display: flex;
        justify-content: center;
      `;
    }
    return css`
      display: none;
    `;
  }}
`;

const MaxWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 24px 60px;
`;

const ExpandMenuListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
`;

const LinkList = styled(NavLinkList)`
  display: flex;
  width: 180px;
  justify-content: center;
`;

const ExternalButtonWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const NerdHomePageLink = styled(Anchor)`
  /* width: 80px; */
  width: 100px;
`;

interface NavigationProps {
  vodaLogoImg: FluidObject;
}

const Navigation = ({ vodaLogoImg }: NavigationProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [mouseOverID, setMouseOverID] = useState(-1);

  return (
    <Component
      onMouseLeave={() => {
        setIsOpen(false);
        setMouseOverID(-1);
      }}
    >
      <MainMenu>
        <ContainerGrid spacing={'xsm'} className="container-grid">
          <ItemGrid desktop={2}>
            <LogoWrapper to="/">
              <LogoText />
            </LogoWrapper>
          </ItemGrid>
          <ItemGrid desktop={8}>
            <NavButtonWrapper
              onMouseOver={() => {
                setIsOpen(true);
              }}
            >
              <NavButton
                to="/functions/usageAnalysis"
                onMouseOver={() => {
                  setMouseOverID(0);
                }}
                isMouseOver={mouseOverID === 0}
              >
                <H4>기능</H4>
              </NavButton>
              <NavButton
                to="/apply/agency"
                onMouseOver={() => {
                  setMouseOverID(1);
                }}
                isMouseOver={mouseOverID === 1}
              >
                <H4>적용</H4>
              </NavButton>
              <NavButton
                to="/pricing"
                onMouseOver={() => {
                  setMouseOverID(2);
                }}
                isMouseOver={mouseOverID === 2}
              >
                <H4>가격</H4>
              </NavButton>
              <NavButton
                to="/contact/form"
                onMouseOver={() => {
                  setMouseOverID(3);
                }}
                isMouseOver={mouseOverID === 3}
              >
                <H4>문의</H4>
              </NavButton>
            </NavButtonWrapper>
          </ItemGrid>

          <ItemGrid desktop={2}>
            <ExternalButtonWrapper>
              <NerdHomePageLink href="http://nerdfactory.ai">
                <Image img={vodaLogoImg} />
              </NerdHomePageLink>
            </ExternalButtonWrapper>
          </ItemGrid>
        </ContainerGrid>
      </MainMenu>

      <ExpandMenu isOpen={isOpen}>
        <MaxWrapper>
          <ContainerGrid spacing={'xsm'}>
            <ItemGrid desktop={2} />
            <ItemGrid desktop={8}>
              <ExpandMenuListWrapper>
                <LinkList
                  onMouseOver={() => {
                    setMouseOverID(0);
                  }}
                  list={[
                    {
                      title: '웹 사이트 사용 분석',
                      to: '/functions/usageAnalysis',
                    },
                    {
                      title: '사용 환경 분석',
                      to: '/functions/environmentAnalysis',
                    },
                    { title: '사용성(UX) 분석', to: '/functions/uxAnalysis' },
                  ]}
                />
                <LinkList
                  onMouseOver={() => {
                    setMouseOverID(1);
                  }}
                  list={[
                    { title: '정부/공공기관 홈페이지', to: '/apply/agency' },
                    { title: '기업 홈페이지', to: '/apply/company' },
                    { title: '온라인 쇼핑몰', to: '/apply/mall' },
                  ]}
                />
                <LinkList />
                <LinkList
                  onMouseOver={() => {
                    setMouseOverID(3);
                  }}
                  list={[
                    { title: '문의', to: '/contact/form' },
                    { title: '자료실', to: '/contact/download' },
                  ]}
                />
              </ExpandMenuListWrapper>
            </ItemGrid>
            {/* <ItemGrid desktop={2}></ItemGrid> */}
          </ContainerGrid>
        </MaxWrapper>
      </ExpandMenu>
    </Component>
  );
};

export default Navigation;
